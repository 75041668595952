import React from 'react';
import PropTypes from 'prop-types';
import { STREAM_KEYS } from 'lib/liveVideo';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { fastChannelStreamNameMap } from '../../lib/fastChannelStreamNameMap';
import { fastChannelImagesAndTextContent } from '../../lib/fastChannelImagesAndTextContent';

import styles from './styles.module.scss';

/**
 * @type {Function}
 * @param {Function} setStreamChannel A function to change the actively played stream channel.
 * @param {boolean} isActive Is the current stream active
 * @param {string} streamKey The name of the channel to play next.
 *  * @param {Function} setShouldReload A function to force reload page upon channel change.
 * @returns {undefined}
 */
export const handleStreamChannelChange = (
  isActive,
  scheduleKey,
  setNetwork,
  setStreamChannel,
  streamKey,
  setShouldReload,
) => {
  if (typeof document !== 'undefined' && document.pictureInPictureElement && !isActive) {
    document.exitPictureInPicture();
  }

  setStreamChannel(streamKey);
  setNetwork(scheduleKey);
  setShouldReload(true);
};

/**
 *  @param {React.ElementRef} node ref to button element
 */
export const setActiveButtonRef = (node) => {
  if (node) {
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};

/**
 * @type {React.FunctionComponent}
 * @param {Function} setStreamChannel A function to change the actively played stream channel.
 * @param {string} streamChannel The name of the channel being played.
 * @param {Function} setNetwork A function to set the current network.
 * @param {Function} setShouldReload A function to set if the page should reload
 * @param {Array<import('lib/liveVideo').STREAM_KEY>} filteredStreamKeys Stream keys to filter out from showing
 * @returns {React.Element[]} An array of React elements that represents the FastChannelSelector buttons.
 */
const generateStreamChannelList = (
  setStreamChannel,
  streamChannel,
  setNetwork,
  setShouldReload,
  filteredStreamKeys,
  launchDarklyFlags,
) => {
  const enableJumpToActiveFlag = launchDarklyFlags?.['enable-watch-hub-jump-to-active-channel'];

  return Object.values(fastChannelStreamNameMap)
    .filter(({ streamKey }) => !filteredStreamKeys.includes(streamKey))
    .map((channel) => {
      const { scheduleKey, streamKey } = channel;
      const isActive = streamChannel === streamKey;
      const channelContent = fastChannelImagesAndTextContent[streamKey] || {};
      return (
        <button
          key={streamKey}
          ref={(isActive && enableJumpToActiveFlag) ? setActiveButtonRef : null}
          className={`${styles.channelButton} ${isActive ? styles.activeChannel : ''}`}
          type="button"
          onClick={() => {
            handleStreamChannelChange(
              isActive,
              scheduleKey,
              setNetwork,
              setStreamChannel,
              streamKey,
              setShouldReload,
            );
          }}
          aria-pressed={isActive}
          data-testid="newsStreamKey"
          aria-label={fastChannelImagesAndTextContent[streamKey].ariaLabel}
        >
          <div>
            <img
              src={channelContent.channelSelectorImage}
              alt={channelContent.altText || `${streamKey} channel`}
              className={styles.channelImage}
            />
          </div>
        </button>
      );
    });
};

/**
 * @type {React.FunctionComponent}
 * @param {object} props The props of the component.
 * @param {Function} props.setStreamChannel A function to change the actively played stream channel.
 * @param {Function} props.setNetwork A function to change the network state for schedule data.
 * @param {Function} props.setShouldReload A function to force reload upon channel change.
 * @param {string} props.streamChannel The name of the channel being played.
 * @returns {React.Element} A React element that represents the FastChannelSelector component.
 */
export const FastChannelSelector = ({
  setStreamChannel,
  streamChannel,
  setNetwork,
  setShouldReload,
}) => {
  const launchDarklyFlags = useFeatureFlagContext();
  const showHubTelemundoFlag = launchDarklyFlags?.['show-hub-telemundo-channels'];
  const filteredStreamKeys = showHubTelemundoFlag ? [] : [
    STREAM_KEYS.TELEMUNDO_CALIFORNIA,
    STREAM_KEYS.TELEMUNDO_FLORIDA,
    STREAM_KEYS.TELEMUNDO_NORESTE,
    STREAM_KEYS.TELEMUNDO_TEXAS];
  return (
    <div className={styles.fastChannelSelector}>
      { generateStreamChannelList(
        setStreamChannel,
        streamChannel,
        setNetwork,
        setShouldReload,
        filteredStreamKeys,
        launchDarklyFlags,
      ) }
    </div>
  );
};

FastChannelSelector.propTypes = {
  setStreamChannel: PropTypes.func.isRequired,
  setNetwork: PropTypes.func.isRequired,
  streamChannel: PropTypes.string.isRequired,
  setShouldReload: PropTypes.func.isRequired,
};
