import nbcChannelSelectorImage from 'assets/images/news/nbc-news-fast-channel-headline.svg';
import nbcDescriptionImage from 'assets/images/news/nbc-news-now-fast-channel-description-image.svg';
import noticiasAhoraChannelSelectorImage from 'assets/images/telemundo/noticias-ahora-fast-channel-headline.svg';
import noticiasAhoraDescriptionImage from 'assets/images/telemundo/noticias-ahora-fast-channel-description-image.svg';
import todayAllDayChannelSelectorImage from 'assets/images/today/today-all-day-fast-channel-headline.svg';
import todayAllDayDescriptionImage from 'assets/images/today/today-all-day-fast-channel-description-image.svg';
import skyNewsChannelSelectorImage from 'assets/images/sky/sky-news-fast-channel-headline.svg';
import skyNewsDescriptionImage from 'assets/images/sky/sky-news-fast-channel-description-image.svg';
import bayAreaChannelSelectorImage from 'assets/images/local/fast-channel/bay-area-fast-channel-headline.svg';
import bayAreaDescriptionImage from 'assets/images/local/fast-channel/bay-area-fast-channel-description-image.svg';
import bostonChannelSelectorImage from 'assets/images/local/fast-channel/boston-fast-channel-headline.svg';
import bostonDescriptionImage from 'assets/images/local/fast-channel/boston-fast-channel-description-image.svg';
import chicagoChannelSelectorImage from 'assets/images/local/fast-channel/chicago-fast-channel-headline.svg';
import chicagoDescriptionImage from 'assets/images/local/fast-channel/chicago-fast-channel-description-image.svg';
import connecticutChannelSelectorImage from 'assets/images/local/fast-channel/connecticut-fast-channel-headline.svg';
import connecticutDescriptionImage from 'assets/images/local/fast-channel/connecticut-fast-channel-description-image.svg';
import dallasChannelSelectorImage from 'assets/images/local/fast-channel/dallas-fast-channel-headline.svg';
import dallasDescriptionImage from 'assets/images/local/fast-channel/dallas-fast-channel-description-image.svg';
import losAngelesChannelSelectorImage from 'assets/images/local/fast-channel/los-angeles-fast-channel-headline.svg';
import losAngelesDescriptionImage from 'assets/images/local/fast-channel/los-angeles-fast-channel-description-image.svg';
import newYorkChannelSelectorImage from 'assets/images/local/fast-channel/new-york-fast-channel-headline.svg';
import newYorkDescriptionImage from 'assets/images/local/fast-channel/new-york-fast-channel-description-image.svg';
import philadelphiaChannelSelectorImage from 'assets/images/local/fast-channel/philadelphia-fast-channel-headline.svg';
import philadelphiaDescriptionImage from 'assets/images/local/fast-channel/philadelphia-fast-channel-description-image.svg';
import sanDiegoChannelSelectorImage from 'assets/images/local/fast-channel/san-diego-fast-channel-headline.svg';
import sanDiegoDescriptionImage from 'assets/images/local/fast-channel/san-diego-fast-channel-description-image.svg';
import southFloridaChannelSelectorImage from 'assets/images/local/fast-channel/south-florida-fast-channel-headline.svg';
import southFloridaDescriptionImage from 'assets/images/local/fast-channel/south-florida-fast-channel-description-image.svg';
import washingtonDcChannelSelectorImage from 'assets/images/local/fast-channel/washington-dc-fast-channel-headline.svg';
import washingtonDcDescriptionImage from 'assets/images/local/fast-channel/washington-dc-fast-channel-description-image.svg';
import dateLineChannelSelectorImage from 'assets/images/dateline/dateline-fast-channel-headline.svg';
import dateLineDescriptionImage from 'assets/images/dateline/dateline-fast-channel-description-image.svg';
import telemundoFloridaChannelSelectorImage from 'assets/images/telemundo/telemundo-florida-fast-channel-headline.svg';
import telemundoFloridaDescriptionImage from 'assets/images/telemundo/telemundo-florida-fast-channel-description-image.svg';
import telemundoNoresteChannelSelectorImage from 'assets/images/telemundo/telemundo-noreste-fast-channel-headline.svg';
import telemundoNoresteDescriptionImage from 'assets/images/telemundo/telemundo-noreste-fast-channel-description-image.svg';
import telemundoTexasChannelSelectorImage from 'assets/images/telemundo/telemundo-texas-fast-channel-headline.svg';
import telemundoTexasDescriptionImage from 'assets/images/telemundo/telemundo-texas-fast-channel-description-image.svg';
import telemundoCaliforniaChannelSelectorImage from 'assets/images/telemundo/telemundo-california-fast-channel-headline.svg';
import telemundoCaliforniaDescriptionImage from 'assets/images/telemundo/telemundo-california-fast-channel-description-image.svg';
import liveBadge from 'assets/icons/fast-channel-live-badge.svg';
import { fastChannelStreamNameMap } from './fastChannelStreamNameMap';

export const fastChannelImagesAndTextContent = {
  [fastChannelStreamNameMap.news.streamKey]: {
    channelSelectorImage: nbcChannelSelectorImage,
    descriptionImage: nbcDescriptionImage,
    descriptionText: 'Live breaking news, the latest headlines, and in-depth reporting. Streaming 24/7.',
    altText: 'NBC News Logo',
    ariaLabel: 'NBC News Now',
  },
  [fastChannelStreamNameMap.noticias.streamKey]: {
    channelSelectorImage: noticiasAhoraChannelSelectorImage,
    descriptionImage: noticiasAhoraDescriptionImage,
    descriptionText: 'Las últimas noticias y las historias más importantes para la comunidad latina.',
    altText: 'Noticias Ahora Logo',
    ariaLabel: 'Noticias Ahora',
  },
  [fastChannelStreamNameMap.today.streamKey]: {
    channelSelectorImage: todayAllDayChannelSelectorImage,
    descriptionImage: todayAllDayDescriptionImage,
    descriptionText: 'TODAY All Day is a 24/7 streaming channel from NBC News where viewers can find everything they love about TODAY.',
    altText: 'Today All Day Logo',
    ariaLabel: 'Today All Day',
  },
  [fastChannelStreamNameMap.bayarea.streamKey]: {
    channelSelectorImage: bayAreaChannelSelectorImage,
    descriptionImage: bayAreaDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the Bay Area',
    altText: 'Bay Area News Logo',
    ariaLabel: 'Bay Area',
  },
  [fastChannelStreamNameMap.boston.streamKey]: {
    channelSelectorImage: bostonChannelSelectorImage,
    descriptionImage: bostonDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the Boston area',
    altText: 'Boston News Logo',
    ariaLabel: 'Boston',
  },
  [fastChannelStreamNameMap.chicago.streamKey]: {
    channelSelectorImage: chicagoChannelSelectorImage,
    descriptionImage: chicagoDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the Chicago area',
    altText: 'Chicago News Logo',
    ariaLabel: 'Chicago',
  },
  [fastChannelStreamNameMap.connecticut.streamKey]: {
    channelSelectorImage: connecticutChannelSelectorImage,
    descriptionImage: connecticutDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in Connecticut',
    altText: 'Connecticut News Logo',
    ariaLabel: 'Connecticut',
  },
  [fastChannelStreamNameMap.dallasFortWorth.streamKey]: {
    channelSelectorImage: dallasChannelSelectorImage,
    descriptionImage: dallasDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the Dallas-Fort Worth area',
    altText: 'Dallas and Fort Worth News Logo',
    ariaLabel: 'Dallas-Fort Worth',
  },
  [fastChannelStreamNameMap.la.streamKey]: {
    channelSelectorImage: losAngelesChannelSelectorImage,
    descriptionImage: losAngelesDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the LA area',
    altText: 'Los Angeles News Logo',
    ariaLabel: 'Los Angeles',
  },
  [fastChannelStreamNameMap.nyc.streamKey]: {
    channelSelectorImage: newYorkChannelSelectorImage,
    descriptionImage: newYorkDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the tri-state area',
    altText: 'New York News Logo',
    ariaLabel: 'New York',
  },
  [fastChannelStreamNameMap.philly.streamKey]: {
    channelSelectorImage: philadelphiaChannelSelectorImage,
    descriptionImage: philadelphiaDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the Philadelphia area',
    altText: 'Philadelphia News Logo',
    ariaLabel: 'Philadelphia',
  },
  [fastChannelStreamNameMap.sd.streamKey]: {
    channelSelectorImage: sanDiegoChannelSelectorImage,
    descriptionImage: sanDiegoDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the San Diego area',
    altText: 'San Diego News Logo',
    ariaLabel: 'San Diego',
  },
  [fastChannelStreamNameMap.southFlorida.streamKey]: {
    channelSelectorImage: southFloridaChannelSelectorImage,
    descriptionImage: southFloridaDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the South Florida area',
    altText: 'South Florida News Logo',
    ariaLabel: 'South Florida',
  },
  [fastChannelStreamNameMap.dc.streamKey]: {
    channelSelectorImage: washingtonDcChannelSelectorImage,
    descriptionImage: washingtonDcDescriptionImage,
    descriptionText: 'The latest local news, breaking updates, live events & weather in the D.C. area',
    altText: 'Washington DC News Logo',
    ariaLabel: 'Washington, D.C.',
  },
  [fastChannelStreamNameMap.dateline.streamKey]: {
    channelSelectorImage: dateLineChannelSelectorImage,
    descriptionImage: dateLineDescriptionImage,
    descriptionText: 'Discover true crime picks from The True Crime Original. All Dateline, all the time.',
    altText: 'Dateline NBC Logo',
    ariaLabel: 'Dateline',
  },
  [fastChannelStreamNameMap.sky.streamKey]: {
    channelSelectorImage: skyNewsChannelSelectorImage,
    descriptionImage: skyNewsDescriptionImage,
    descriptionText: 'Sky News delivers breaking news, headlines and top stories from business, politics, entertainment and more in the UK and worldwide.',
    altText: 'Sky News Logo',
    ariaLabel: 'Sky News',
  },
  [fastChannelStreamNameMap.telemundo_noreste.streamKey]: {
    channelSelectorImage: telemundoNoresteChannelSelectorImage,
    descriptionImage: telemundoNoresteDescriptionImage,
    descriptionText: 'Las noticias de última hora, temas locales, y el tiempo en el Noreste.',
    altText: 'Telemundo Noreste Logo',
    ariaLabel: 'Telemundo-Noreste',
  },
  [fastChannelStreamNameMap.telemundo_florida.streamKey]: {
    channelSelectorImage: telemundoFloridaChannelSelectorImage,
    descriptionImage: telemundoFloridaDescriptionImage,
    descriptionText: 'Las noticias de última hora, temas locales, y el tiempo en Florida.',
    altText: 'Telemundo Florida Logo',
    ariaLabel: 'Telemundo-Florida',
  },
  [fastChannelStreamNameMap.telemundo_california.streamKey]: {
    channelSelectorImage: telemundoCaliforniaChannelSelectorImage,
    descriptionImage: telemundoCaliforniaDescriptionImage,
    descriptionText: 'Las noticias de última hora, temas locales, y el tiempo en California.',
    altText: 'Telemundo California Logo',
    ariaLabel: 'Telemundo-California',
  },
  [fastChannelStreamNameMap.telemundo_texas.streamKey]: {
    channelSelectorImage: telemundoTexasChannelSelectorImage,
    descriptionImage: telemundoTexasDescriptionImage,
    descriptionText: 'Las noticias de última hora, temas locales, y el tiempo en Texas.',
    altText: 'Telemundo Texas Logo',
    ariaLabel: 'Telemundo-Texas',
  },
  liveBadge,
};
