import React, { useState, useEffect, useRef } from 'react';
import Head from 'next/head';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { setPageView, setSection } from 'redux/modules/shared';
import { loadFront } from 'redux/modules/front';
import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { setLinkHeaders } from 'lib/setLinkHeaders';
import { useBroadcastScheduleData } from 'lib/Hooks/useBroadcastScheduleData';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import { GlobalMetadata, FrontMetadata } from 'components/PageMetadata';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';
import { FastChannelSelector } from 'components/FastChannelSelector';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import IconfontStyleTag from 'components/IconfontStyleTag';

import { fastChannelImagesAndTextContent } from '../../src/lib/fastChannelImagesAndTextContent';
import { fastChannelStreamNameMap } from '../../src/lib/fastChannelStreamNameMap';

// Styling imports
import globalContainerStyles from '../globalContainerStyles.module.scss';
import styles from './styles.module.scss';

const pageView = 'front';

const omegaStreamNames = [
  fastChannelStreamNameMap.dateline.streamKey,
  fastChannelStreamNameMap.news.streamKey,
  fastChannelStreamNameMap.noticias.streamKey,
  fastChannelStreamNameMap.sky.streamKey,
  fastChannelStreamNameMap.today.streamKey,
];

export const navbarConfig = {
  /**
   * @returns {string}
   */
  theme() {
    return NAVBAR_THEME.DARK;
  },
};

/**
 * @param root0
 * @param root0.shared
 */
const mapStateToProps = ({
  shared,
}) => ({
  isChromeless: shared.isChromeless,
});

/**
 * @param props
 * @returns {React.ReactElement}
 */
const FastChannel = (props) => {
  const {
    isChromeless = false,
    layout,
    domain,
    vertical,
  } = props;

  // default the page to start with the News Now stream
  const [streamChannel, setStreamChannel] = useState(fastChannelStreamNameMap.news.streamKey);
  const [network, setNetwork] = useState(fastChannelStreamNameMap.news.scheduleKey);
  const [shouldOmegaUnmount, setShouldOmegaUnmount] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);

  const channelSelectorRef = useRef({});
  const shadowRef = useRef({});

  const { scheduleData } = useBroadcastScheduleData(network);

  const isDateline = scheduleData?.[1]?.title === 'Dateline NBC';
  const nextEpisodeText = isDateline ? scheduleData?.[1]?.description : scheduleData?.[1]?.title;

  /**
   * add or remove styles
   */
  const handleChannelSelectorShadow = () => {
    const { scrollHeight, scrollTop, clientHeight } = channelSelectorRef.current;
    const { classList } = shadowRef.current;

    if (scrollHeight - scrollTop - clientHeight < 62) {
      classList?.add(styles.hideScrollShadow);
    } else if (classList?.contains(styles.hideScrollShadow)) {
      classList?.remove(styles.hideScrollShadow);
    }
  };

  // Adds and removes drop shadow of channel selector when container is scrolled to bottom
  useEffect(() => {
    channelSelectorRef.current.addEventListener('scroll', handleChannelSelectorShadow);
    window.addEventListener('resize', handleChannelSelectorShadow);

    return () => window.removeEventListener('resize', handleChannelSelectorShadow);
  }, []);

  // This useEffect is needed so we can trigger the 'sigma_cvsdk_playerInit'
  // analytics event (within Omega repo) every time the user switches Omega channels.
  // The event only fires upon mounting <OmegaVideoPlayer />, so we intentionally
  // unmount and re-mount every time the user switches between Omega channels.
  // We might want to consider having the Omega repo fire this event upon
  // receiving a new channel, rather than doing this forced unmount from Ramen.
  useEffect(() => {
    if (shouldOmegaUnmount) {
      setShouldOmegaUnmount(false);
    }
  }, [shouldOmegaUnmount]);

  const setStreamChannelHandler = (stream) => {
    setStreamChannel(stream);

    if (omegaStreamNames.includes(stream)) {
      setShouldOmegaUnmount(true);
    }
  };

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const channelEntry = Object.values(fastChannelStreamNameMap).find(
        (channel) => channel.hashUrl === hash,
      );
      if (channelEntry) {
        setStreamChannel(channelEntry.streamKey);
        setNetwork(channelEntry.scheduleKey);
      }
    }
  }, []);

  useEffect(() => {
    const iframe = document.getElementById('#iframeStream');
    iframe?.remove();

    if (omegaStreamNames.includes(streamChannel)) {
      return;
    }

    const embed = document.querySelector('.video-live__core-video-container');

    embed.innerHTML = '';
    const script = document.createElement('script');
    script.src = streamChannel;
    script.async = true;

    embed.appendChild(script);
  }, [streamChannel]);

  useEffect(() => {
    const channelEntry = Object.values(fastChannelStreamNameMap).find(
      (channel) => channel.streamKey === streamChannel,
    );
    if (channelEntry && window.location.hash !== channelEntry.hashUrl) {
      // Use pushState to update the hash without reloading the page
      window.history.pushState(null, '', channelEntry.hashUrl);
      if (shouldReload) {
        window.location.reload();
        setShouldReload(false);
      }
    }
  }, [streamChannel]);

  useEffect(() => {
    /**
     *  handles url hash change and updates the stream accordingly
     */
    const handleHashChange = () => {
      const { hash } = window.location;
      const channelEntry = Object.values(fastChannelStreamNameMap).find(
        (channel) => channel.hashUrl === hash,
      );
      if (channelEntry && channelEntry.streamKey !== streamChannel) {
        setStreamChannel(channelEntry.streamKey);
        setNetwork(channelEntry.scheduleKey);
      }
    };
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [streamChannel]);

  const {
    altText,
    descriptionImage,
    descriptionText,
  } = fastChannelImagesAndTextContent[streamChannel];

  const liveBadge = fastChannelImagesAndTextContent?.liveBadge;

  const renderPlayer = () => {
    if (omegaStreamNames.includes(streamChannel)) {
      if (shouldOmegaUnmount) {
        return null;
      }

      return (
        <OmegaVideoPlayer
          stream={streamChannel}
          mutedAutoplay
          stickyEnabled
          autoplay
        />
      );
    }

    return (
      <div
        className="video-live__core-video-container"
        data-testid="iframe-container"
        id="iframeStream"
      />
    );
  };

  const playerContainerStyles = classNames(
    styles.playerContainer,
    {
      [styles.chromeless]: isChromeless,
    },
  );

  const content = (
    <div className={styles.fastChannelPage} data-testid="fast-channel">
      <div className={styles.container}>

        <div className={playerContainerStyles}>{renderPlayer()}</div>

        <div className={styles.descAndNextEpisode}>
          <div className={styles.description}>
            <img alt="live stream icon" src={liveBadge} className={styles.image} />
            <img alt={altText} src={descriptionImage} className={styles.image} />
            <p className={styles.text}>{descriptionText}</p>
          </div>
          {nextEpisodeText && (
            <div className={styles.nextEpisode} data-testid="up-next">
              <p className={styles.text}>UP NEXT</p>
              <p className={styles.text}>{nextEpisodeText}</p>
            </div>
          )}
        </div>

        <div
          className={styles.channelSelectorContainer}
          data-testid="channel-selector-container"
          ref={channelSelectorRef}
        >
          <FastChannelSelector
            streamChannel={streamChannel}
            setNetwork={setNetwork}
            setStreamChannel={setStreamChannelHandler}
            setShouldReload={setShouldReload}
          />
          <div className={styles.scrollShadow} ref={shadowRef} data-testid="scroll-shadow" />
        </div>

      </div>
    </div>
  );

  const wrappedContent = isChromeless ? (
    content
  ) : (
    <div className={styles.pageWrapper}>
      <HeaderAndFooter
        adsDisabledByParentComponent
        isNavbarSticky
        layout={layout}
      >
        {content}
      </HeaderAndFooter>
    </div>
  );

  return (
    <>
      <IconfontStyleTag />
      <GlobalMetadata />
      <FrontMetadata curation={{}} vertical={vertical} domain={domain} />
      <Head>
        <title key="title-description">
          Watch Live News 24/7: NBC News Now, Sky News, Today All Day, Local Coverage and More.
        </title>
        <meta
          key="watch-description"
          name="description"
          content="Stream live news 24/7, including NBC News Now, Sky News, Dateline, Noticias, Today All Day, and more. Choose your local market and stream for free today."
        />
      </Head>
      <div id="content" className={globalContainerStyles.container}>
      <ThemesCssBundle vertical={vertical} />
        {wrappedContent}
      </div>
      <AnalyticsLaunchScripts />
    </>
  );
};

/**
 * @param ctx
 * @returns {object}
 */
FastChannel.getInitialProps = async (ctx) => {
  const {
    res: {
      locals: {
        vertical,
        fullUrl,
      },
    },
    store,
  } = ctx;

  const slugPath = new URL(fullUrl).pathname.replace(/^\//, '');

  const promises = [
    store.dispatch(setPageView(pageView)),
    store.dispatch(setSection(slugPath)),
    store.dispatch(loadFront(vertical, slugPath)),
  ];

  await Promise.all(promises);

  setLinkHeaders(ctx.res, vertical);
  return {
    navigating: false,
    pageView,
  };
};

FastChannel.propTypes = {
  isChromeless: PropTypes.bool,
  layout: servicesLayoutPropType.isRequired,
  domain: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
};

export default navbar(navbarConfig)(
  connect(mapStateToProps)(
    FastChannel,
  ),
);

// exported for testing
export { FastChannel as UnwrappedFastChannelPage };
