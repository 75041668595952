import { STREAM_KEYS } from 'lib/liveVideo';

// the order of this map directly affects the order of the channels rendered by <FastChannelSelector />
export const fastChannelStreamNameMap = {
  news: {
    streamKey: STREAM_KEYS.NEWS_NOW,
    hashUrl: ' ',
    scheduleKey: 'NEWS_NOW',
  },
  nyc: {
    streamKey: STREAM_KEYS.NYC,
    hashUrl: '#new-york',
    scheduleKey: 'NY',
  },
  la: {
    streamKey: STREAM_KEYS.LA,
    hashUrl: '#los-angeles',
    scheduleKey: 'LA',
  },
  chicago: {
    streamKey: STREAM_KEYS.CHICAGO,
    hashUrl: '#chicago',
    scheduleKey: 'CHICAGO',
  },
  dallasFortWorth: {
    streamKey: STREAM_KEYS.DFW,
    hashUrl: '#dallas-fort-worth',
    scheduleKey: 'DFW',
  },
  philly: {
    streamKey: STREAM_KEYS.PHILLY,
    hashUrl: '#philadelphia',
    scheduleKey: 'PHILADELPHIA',
  },
  dc: {
    streamKey: STREAM_KEYS.DC,
    hashUrl: '#washington',
    scheduleKey: 'DC',
  },
  boston: {
    streamKey: STREAM_KEYS.BOSTON,
    hashUrl: '#boston',
    scheduleKey: 'BOSTON',
  },
  bayarea: {
    streamKey: STREAM_KEYS.BAY_AREA,
    hashUrl: '#bay-area',
    scheduleKey: 'SF_BAY',
  },
  southFlorida: {
    streamKey: STREAM_KEYS.MIAMI,
    hashUrl: '#miami',
    scheduleKey: 'SOUTH_FLORIDA',
  },
  sd: {
    streamKey: STREAM_KEYS.SD,
    hashUrl: '#san-diego',
    scheduleKey: 'SAN_DIEGO',
  },
  connecticut: {
    streamKey: STREAM_KEYS.HARTFORD,
    hashUrl: '#connecticut',
    scheduleKey: 'CONNECTICUT',
  },
  telemundo_florida: {
    streamKey: STREAM_KEYS.TELEMUNDO_FLORIDA,
    hashUrl: '#telemundo-florida',
    scheduleKey: 'TELEMUNDO_FLORIDA',
  },
  telemundo_noreste: {
    streamKey: STREAM_KEYS.TELEMUNDO_NORESTE,
    hashUrl: '#telemundo-noreste',
    scheduleKey: 'TELEMUNDO_NORESTE',
  },
  telemundo_texas: {
    streamKey: STREAM_KEYS.TELEMUNDO_TEXAS,
    hashUrl: '#telemundo-texas',
    scheduleKey: 'TELEMUNDO_TEXAS',
  },
  telemundo_california: {
    streamKey: STREAM_KEYS.TELEMUNDO_CALIFORNIA,
    hashUrl: '#telemundo-california',
    scheduleKey: 'TELEMUNDO_CALIFORNIA',
  },
  noticias: {
    streamKey: STREAM_KEYS.NOTICIAS_AHORA,
    hashUrl: '#noticias-telemundo-ahora',
    scheduleKey: 'NOTICIAS_AHORA',
  },
  sky: {
    streamKey: STREAM_KEYS.SKY_NEWS,
    hashUrl: '#skynews',
    scheduleKey: 'SKYNEWS',
  },
  dateline: {
    streamKey: STREAM_KEYS.DATELINE,
    hashUrl: '#dateline',
    scheduleKey: 'DL247',
  },
  today: {
    streamKey: STREAM_KEYS.TODAY_ALL_DAY,
    hashUrl: '#allday',
    scheduleKey: 'TODAY_ALL_DAY',
  },
};
